import React from "react"
import useInterval from "../utils/useInterval"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const IndexPage = ({ data }, location) => {
  const [becomeDigital, setBecomeDigital] = React.useState("Become digital")
  const siteTitle = data.site.siteMetadata.title
  const randomizeText = text => {
    return text
      .split("")
      .map(character => {
        if (Math.floor(Math.random() * 10)) {
          return character.toUpperCase()
        }
        return character.toLowerCase()
      })
      .join("")
  }

  useInterval(() => setBecomeDigital(randomizeText(becomeDigital)), 100)
  return (
    <>
      <Layout title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <article className="post-content page-template no-image">
          <h2 className="page-head-title">
            A boutique digital agency focused on artistry and customer success
          </h2>
          <div className="post-content-body">
            <div className="row gtr-uniform">
              <div className="col-5 col-12-small">
                <figure className="kg-card kg-image-card">
                  <Img
                    fluid={data.benchAccounting.childImageSharp.fluid}
                    className="kg-image"
                  />
                </figure>
              </div>
              <div className="col-7 col-12-small" style={{ display: "flex" }}>
                <h2 style={{ alignSelf: "center" }}>{becomeDigital}</h2>
              </div>
            </div>
          </div>
        </article>
      </Layout>
    </>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(relativePath: { eq: "do-it-all.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <IndexPage location={props.location} data={data} {...props} />
    )}
  />
)
